/* eslint-disable valid-jsdoc */
// eslint-disable-next-line no-shadow-restricted-names
;(function ($, window, undefined) {
  'use strict'

  var EVENTNS = '.multilevel-menu'

  var ATTRIBUTE_PLUGIN = 'multilevel-menu-plugin'
  var ATTRIBUTE_DATA = 'multilevel-menu'
  var ATTRIBUTE_OPTIONS = 'multilevel-menu-options'

  var CLS_FIXED_TOP = 's-menu-multilevel_fixed-top'
  var CLS_RIGHT_SIDE = 's-menu-multilevel_right_side'

  // Функции-помощники -------------------------------------------------------------------------------------------------

  function e(eventName, instance) {
    var eventNs = EVENTNS + (instance ? '.' + instance.id : '')
    return (eventName || '').split(/\s+/g)
      .map(function (e) { return e + eventNs })
      .join(' ')
  }

  function offsetBottom(elem) {
    var $elem = $(elem)
    return $elem.offset().top + $elem.outerHeight()
  }

  function positionBottom(elem) {
    var $elem = $(elem)
    return $elem.position().top + $elem.outerHeight()
  }

  function camelizeAttrName(str) {
    return str.replace(/-(\w)/g, function (match) { return match[1].toUpperCase() })
  }

  function startsWithUppper(str) {
    if (str.length === 0) { return false }
    var firstChar = str.substr(0, 1)
    return firstChar === firstChar.toUpperCase()
  }

  /**
   * Подготавливает данные для работы метода renderMenu
   * Item = {
   *   text: String,
   *   link: String,
   *   sub:  Menu
   * };
   * Menu = [Item];
   *
   * NewItem {
   *   text: String,
   *   link: String,
   *   sub:  NewMenu,
   *   index: Integer,
   *   parent: NewItem
   * }
   * NewMenu = [NewItem]
   *
   * @param   Menu    menu
   * @param   NewItem parent
   * @returns NewMenu data
   */
  function prepareData(menu, parent) {
    parent = parent || null
    var newMenu = []
    for (var index = 0, l = menu.length; index < l; index++) {
      var item = menu[index]
      if (item === '-') {
        newMenu.push('-')
      } else {
        var newItem = {
          text:   item.text,
          link:   item.link || '',
          cls:    item.cls || '',
          index:  index,
          parent: parent,
          siblings: newMenu
        }
        var newSub = prepareData(item.sub || [], newItem)
        newItem.sub = newSub
        newMenu.push(newItem)
      }
    }
    return newMenu
  }

  // Прототип экземпляра плагина ---------------------------------------------------------------------------------------

  function Plugin(activator, options) {
    if (options.override) {
      $.extend(this, options.override)
      delete options.override
    }
    this.init(activator, options)
  }

  Plugin.instances = { maxId: 0 }

  Plugin.prototype = {
    init: function (activator, options) {
      this.id = ++Plugin.instances.maxId
      Plugin.instances[this.id] = this
      this.$activator = $(activator).data(ATTRIBUTE_PLUGIN, this)
      this.initOptions(options)

      this.$container = $(this.opts.container || this.$activator)
      this.$menu = this.renderRootMenu(prepareData(this.opts.data))
        .appendTo(this.$container)
      this.$overlay = (this.opts.overlay ? this.renderOverlay() : $(null))
      this.resetPreserveSubmenuVars()
      this.bindHandlers()
    },

    initOptions: function (options) {
      var camelizedDataAttr = camelizeAttrName(ATTRIBUTE_DATA)
      var camelizedPluginAttr = camelizeAttrName(ATTRIBUTE_PLUGIN)
      var camelizedOptionsAttr = camelizeAttrName(ATTRIBUTE_OPTIONS)
      var dataOptions = {}
      $.each(this.$activator.data(), function (attrName, value) {
        if (attrName === camelizedPluginAttr) {
          return // continue;
        }
        if (attrName === camelizedOptionsAttr) {
          $.extend(true, dataOptions, value)
          return // continue;
        }
        if (attrName.substr(0, camelizedDataAttr.length) !== camelizedDataAttr) {
          return // continue;
        }
        var optName = attrName.substr(camelizedDataAttr.length)
        if (optName.length === 0 || startsWithUppper(optName) === false) {
          return // continue;
        }
        dataOptions[optName.toLowerCase()] = value
      })
      var data = this.$activator.data(ATTRIBUTE_DATA)
      this.opts = $.extend(true, {}, dataOptions, options, data ? { data: data } : {})
    },

    renderRootMenu: function (menu) {
      var $menu = this.renderMenu(menu)
      if (this.opts.upper_corner) {
        $menu
          .addClass('s-menu-multilevel_with-upper-corner')
          .append('<div class="s-menu-multilevel__upper-corner"></div>')
      }
      if (this.opts.cls) {
        $menu.addClass(this.opts.cls)
      }
      return $menu
    },

    renderOverlay: function () {
      return null
    },

    bindHandlers: function () {
      $(window).on(e('scroll', this), $.throttle(70, $.proxy(function () {
        if (this.$menu.is(':visible')) {
          this.disposeMenu()
        }
      }, this)))

      // Аналог clickoutside
      $('body').on(e('click', this), $.proxy(function (event) {
        var eventTarget = event.target
        var filterFunc = function () { return this === eventTarget || $.contains(this, eventTarget) }
        if (this.$menu.filter(filterFunc).length === 0 &&
          this.$container.filter(filterFunc).length === 0 &&
          this.$activator.filter(filterFunc).length === 0
        ) {
          this.hideMenu()
        }
      }, this))

      this.$activator
        .on(e(this.opts.activation === 'hover' ? 'mouseenter' : 'click'), $.proxy(function (event) {
          if (event.type === 'click') {
            event.preventDefault()
          }
          if (this.$menu.find(event.target).length === 0) {
            this.$menu.is(':hidden') || offsetBottom(this.$menu) < offsetBottom(this.$activator)
              ? this.showMenu()
              : this.hideMenu()
          }
        }, this))

      if (this.opts.activation === 'hover') {
        this.$activator.on(e('mouseleave'), $.proxy(function (event) {
          this.hideMenu()
        }, this))
      }

      this.$container
        .on(e('click'), '.s-menu-multilevel__item', function (event) {
          var $item = $(this)
          if (event.which !== 1 || $item.hasClass('js-ignore-multilevel-handler')) {
            return
          }

          event.preventDefault()
          event.stopPropagation()

          var url = $item.find('>a').attr('href')

          if (url) {
            if (event.ctrlKey || event.metaKey) {
              window.open(url, '_blank')
              window.focus()
            } else {
              window.location.assign(url)
            }
          }
        })
        .on(e('mouseout'), '.s-menu-multilevel__item', $.proxy(function (event) {
          event.stopPropagation()
          if (this.activeTimeout === null) {
            this.keepActivateItem(event.currentTarget, event)
          }
          this.overItem = null
        }, this))
        .on(e('mouseover'), '.s-menu-multilevel__item', $.proxy(function (event) {
          event.stopPropagation()
          this.overItem = event.currentTarget
          if (
            this.activeItem === null || this.activeTimeout === null || this.activeItem === this.overItem ||
            $.contains(this.activeItem, this.overItem) ||
            $.contains(this.overItem, this.activeItem)
          ) {
            this.checkItemState()
          }
        }, this))
        .on(e('mouseover'), '.s-menu-multilevel', function (event) { event.stopPropagation() })
        .on(e('mousemove'), '.s-menu-multilevel', $.proxy(function (event) {
          if (this.activeTimeout === null) { return }
          if (this.submenuAngles === null) {
            if (this.overItem) {
              this.checkItemState()
            }
            return
          }
          // Угол отклонения от оси X (ось Y инвертирована)
          var angle = Math.atan2(event.pageY - this.mousePos.y, event.pageX - this.mousePos.x)
          if (angle < this.submenuAngles.up || angle > this.submenuAngles.down) {
            this.checkItemState()
          }
        }, this))
    },

    destroy: function () {
      $(window).off(e('', this))
      $('body').off(e('', this))
      this.$container.off(EVENTNS)
      this.$activator.off(EVENTNS)
      this.$activator.removeData(ATTRIBUTE_PLUGIN)
      this.$menu.remove()
      this.$overlay.remove()
    },

    setData: function (data) {
      this.$menu.remove()
      this.$menu = this.renderRootMenu(prepareData(data)).appendTo(this.$container)
    },

    showMenu: function () {
      this.$overlay.fadeIn('fast')
      this.$menu
        .removeData('init_scrolltop')
        .show()
      this.disposeMenu()
    },

    hideMenu: function () {
      this.$menu.hide()
      this.$overlay.fadeOut('fast')
      this.deactivateItem()
    },

    /**
     * @see prepareData
     * @param NewMenu data
     * @returns {*|HTMLElement}
     */
    renderMenu: function (menu) {
      if (menu.length === 0) {
        return $(null)
      }

      var $menu = $('<div role="menu" class="s-menu-multilevel"></div>')
      var $list = $('<ol class="s-menu-multilevel__list"></ol>').appendTo($menu)

      $.each(menu, $.proxy(function (i, item) {
        $list.append(this.renderMenuItem(item))
      }, this))

      return $menu
    },

    renderMenuItem: function (item) {
      if (item === '-') {
        return $('<li class="s-menu-multilevel__delimiter"></li>')
      }

      var subCount = item.sub.length
      var siblingsCount = item.siblings.length
      var hasSubmenu = subCount > 0
      var hasRelative = hasSubmenu && item.index >= subCount
      // Если есть место для отрисовки подменю вниз, так и делаем, иначе вверх
      var hasSubmenuUp = hasRelative && subCount > siblingsCount - item.index
      var parent = item.parent
      var hasCorner = parent &&
      // Сравниваем текущий индекс:
      // 1) с родительским, если меню прибито к верху родительского меню (и соотв-но индексы совпадают)
        item.index === (parent.index < siblingsCount ? parent.index
          // 2) с первым (если меню отрисовано от род. пункта вниз) или последним (если от род. пункта вверх)
          : siblingsCount > parent.siblings.length - parent.index ? siblingsCount - 1 : 0)

      var $submenu
      if (hasSubmenu) {
        $submenu = $('<div class="s-menu-multilevel__nested"></div>').append(this.renderMenu(item.sub))
      }

      var $item = $('<li role="menuitem" class="s-menu-multilevel__item"></li>')
        .addClass(hasSubmenu ? 's-menu-multilevel__item_has-submenu' : '')
        .addClass(hasRelative ? 's-menu-multilevel__item_has-relative-submenu' : '')
        .addClass(hasSubmenuUp ? 's-menu-multilevel__item_has-relative-submenu-to-up' : '')
        .addClass(item.cls || '')

      var $link = (item.link
        ? $('<a class="s-menu-multilevel__label"></a>').attr('href', item.link)
        : $('<span class="s-menu-multilevel__label"></span>')
      ).text(item.text)

      return $item
        .append($link)
        .append(hasSubmenu ? $submenu : null)
        .append(hasCorner ? '<div class="s-menu-multilevel__corner"></div>' : null)
    },

    // ------------------------------------------------------------------------------------------------------------------
    // Позиционирование меню на странице в 3-х случаях:
    //  - страница НЕ прокручена вниз, кнопка НЕ на прибитой кверху плашке
    //    (меню сразу под кнопкой)
    //  - страница прокручена вниз, кнопка на прибитой кверху плашке,
    //    но высота прокрутки меньше, чем та, на которой было вызвано меню
    //    (меню сразу под кнопкой)
    //  - страница прокручена вниз, кнопка на прибитой кверху плашке,
    //    но высота прокрутки больше, чем та, на которой было вызвано меню
    //    (меню заезжает под кнопку)
    disposeMenu: function () {
      var activatorIsFixed = this.$activator.parents().filter(function () { return $(this).css('position') === 'fixed' }).length > 0
      var is_right_side = this.$menu.hasClass(CLS_RIGHT_SIDE)

      // Кнопка не фиксирована на экране
      if (activatorIsFixed === false) {
        this.$menu
          .removeClass(CLS_FIXED_TOP)
          .css({
            top:  offsetBottom(this.$activator) - this.$menu.offsetParent().offset().top,
            left: is_right_side ? 'inherit' : (this.$container.css('position') === 'static' ? this.$container.position().left : 0)
          })
        return
      }

      var wasShown = this.$menu.data('init_scrolltop') === undefined // Меню было только что вызвано
      var scrollTop = Math.max(0, window.scrollY)
      var initScrollTop = wasShown === false
        ? (this.$menu.data('init_scrolltop'))
        : (this.$menu.data('init_scrolltop', scrollTop), scrollTop)
      // Текущий скролл выше того, что был при вызове меню
      if (scrollTop < initScrollTop) {
        this.$menu
          .data('init_scrolltop', scrollTop)
          .addClass(CLS_FIXED_TOP)
          .css({
            top:  positionBottom(this.$activator) - this.$menu.offsetParent().offset().top,
            left: is_right_side ? 'inherit' : (this.$activator.offset().left)
          })
      } else {
        if (this.$menu.hasClass(CLS_FIXED_TOP) || wasShown) {
          this.$menu
            .data('init_scrolltop', scrollTop)
            .removeClass(CLS_FIXED_TOP)
            .css({
              top:  positionBottom(this.$activator) + scrollTop - this.$menu.offsetParent().offset().top,
              left: is_right_side ? 'inherit' : (this.$container.css('position') === 'static' ? this.$container.position().left : 0)
            })
        }
      }
    },

    // Механизм, позволяющий перевести курсор с пункта на раскрытое подменю по диагонали -------------------------------
    resetPreserveSubmenuVars: function () {
      this.activeTimeout = null
      this.activeItem = null
      this.overItem = null
      this.mousePos = { x:0, y:0 }
      this.submenuAngles = null
    },

    checkItemState: function () {
      if (this.activeTimeout !== null) {
        clearTimeout(this.activeTimeout)
        this.activeTimeout = null
        this.submenuAngles = null
      }

      this.deactivateItem(this.overItem)
      this.activeItem = this.overItem
      $(this.overItem).addClass('s-menu-multilevel__item_active')
      if (this.overItem === null && this.opts.activation === 'hover') {
        this.hideMenu()
      }
    },

    keepActivateItem: function (item, event) {
      var $item = $(item)
      if ($item.hasClass('s-menu-multilevel__item_has-submenu')) {
        this.mousePos = { x: event.pageX, y: event.pageY }
        var $submenu = $item.find(' > .s-menu-multilevel__nested > .s-menu-multilevel')
        var submenuOffset = $submenu.offset()
        var dx = submenuOffset.left - this.mousePos.x
        var dy = submenuOffset.top - this.mousePos.y
        var accurancy = 5 / 180 * Math.PI
        this.submenuAngles = {
          up:   Math.atan2(dy, dx) - accurancy,
          down: Math.atan2(dy + $submenu.outerHeight(), dx) + accurancy
        }
      } else {
        this.submenuAngles = null
      }
      this.activeTimeout = setTimeout($.proxy(this.checkItemState, this), 500)
    },

    deactivateItem: function (tillItem) {
      // Активных пунктов меню нет
      if (!this.activeItem) { return }
      // Не указаны элементы, чъя активность не должна быть снята, снимаем активность со всех активных пунктов
      if (!tillItem) {
        this.$menu.find('.s-menu-multilevel__item_active').removeClass('s-menu-multilevel__item_active')
        this.activeItem = null
        return
      }
      // Элементы, чъя активность не должна быть снята
      var tillParents = $(tillItem).parentsUntil(this.$menu, '.s-menu-multilevel__item').toArray()
      tillParents.unshift(tillItem)
      var $tillItemWithParents = $(tillParents)

      // Текущий активный пункт входит в число пунктов, которые не нужно деактивировать
      if ($tillItemWithParents.is(this.activeItem)) { return }

      this.$menu
        .find('.s-menu-multilevel__item_active')
        .filter(function () { return $tillItemWithParents.is(this) === false })
        .removeClass('s-menu-multilevel__item_active').end()

      this.activeItem = $tillItemWithParents.filter('.s-menu-multilevel__item_active')[0] || null
    }
  }

  $.fn.multilevelMenu = function (options) {
    options = options || {}
    if (typeof options === 'string' || (typeof options === 'object' && options instanceof String)) {
      var args = Array.prototype.slice.call(arguments, 1)
      return this
        .map(function () {
          return $(this).data(ATTRIBUTE_PLUGIN)
        })
        .map(function () {
          return this[options].apply(this, args)
        })[0]
    } else {
      return this
        .filter(function () {
          return !$(this).data(ATTRIBUTE_PLUGIN)
        })
        .each(function () {
          // eslint-disable-next-line no-new
          new Plugin(this, options)
        })
    }
  }
})(window.jQuery, window)
